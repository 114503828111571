import styled from "astroturf"
import React from "react"
import ratingDevelopersImage from "../../images/rating-developers.svg"
import ratingAutsorsersImage from "../../images/rating-autsorsers.svg"
import { GatsbyImgBackDevelopmentHead } from "../../images/pageBackDevelopment/GatsbyImgBackDevelopmentHead"

export const ScreenHello = () => {
  const onClickSubmitApplication = () => {
    const writeToUs = document.getElementById("writeToUs")
    if (writeToUs) {
      writeToUs.scrollIntoView({ behavior: "smooth" })
    }
  }

  return (
    <Container>
      <div className={"container"}>
        <BlockTitle>
          <h1>Backend разработка</h1>
          <h2>Разработаем гибкую, масштабируемую и отказоустойчивую архитектуру</h2>
          <WhiteButton onClick={onClickSubmitApplication}>Оставить заявку</WhiteButton>

          <ContentSecondPart>
            <RatingsRow>
              <RatingDevelopersImage src={ratingDevelopersImage} alt={"рейтинг мобильных разработчиков"} />
              <RatingAutsorsersImage src={ratingAutsorsersImage} alt={"рейтинг аутсорсеров"} />
            </RatingsRow>
          </ContentSecondPart>
        </BlockTitle>
        <BlockImage>
          <GatsbyImgBackDevelopmentHead />
        </BlockImage>
      </div>
    </Container>
  )
}

const Container = styled.div`
  padding-top: 150px;
  background: #24d8ff;

  :global(.container) {
    margin-top: 110px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 100px;

    @media screen and (max-width: 1670px) {
      grid-column-gap: 30px;
    }

    @media screen and (max-width: 1309px) {
      grid-template-columns: 1fr;
    }

    @media (max-width: 991px) {
      margin-top: 0;
    }
  }

  @media (max-width: 420px) {
    padding-top: 100px;
  }
`

const BlockTitle = styled.div`
  h1 {
    font-family: "Gilroy", sans-serif;
    font-weight: bold;
    font-size: 64px;
    color: #ffffff;

    @media (max-width: 1599px) {
      font-size: 50px;
    }

    @media (max-width: 420px) {
      font-size: 30px;
    }
  }

  h2 {
    font-family: "Gilroy", sans-serif;
    font-weight: 500;
    font-size: 32px;
    line-height: 130%;
    color: #ffffff;

    @media (max-width: 1599px) {
      font-size: 24px;
    }

    @media (max-width: 420px) {
      font-size: 18px;
    }
  }
`

const BlockImage = styled.div`
  display: flex;

  div {
    width: 100%;
  }

  @media screen and (max-width: 1359px) {
    margin-right: -150px;
  }
  @media screen and (max-width: 1309px) {
    display: none;
  }
`

const WhiteButton = styled.a`
  background: #ffffff;
  cursor: pointer;
  border-radius: 30px;
  padding: 18px 60px;
  font-family: "Gilroy", sans-serif;
  font-weight: 600;
  font-size: 20px;
  color: #102037;

  margin-top: 40px;
  display: flex;
  width: fit-content;

  &:hover {
    background-color: #dee3eb;
  }

  @media (max-width: 1599px) {
    padding: 13px 60px;
  }
`

const ContentSecondPart = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-bottom: 40px;
`

const RatingsRow = styled.div`
  display: flex;
  align-items: flex-start;
`

const RatingDevelopersImage = styled.img`
  width: 163px;
  @media screen and (max-width: 1199px) {
    width: 148px;
  }
`

const RatingAutsorsersImage = styled.img`
  width: 169px;
  margin-left: 110px;
  @media screen and (max-width: 1199px) {
    width: 147px;
    margin-left: 30px;
  }
  @media screen and (max-width: 374px) {
    margin-left: 10px;
  }
`
